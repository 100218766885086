import styled from "styled-components";

export const Header = () => (
  <Wrapper>
    <Logo target="_blank" href="https://futjesus.dev">
      <Image src="/logo-white.svg" alt="logo" width={60} height={30} />
    </Logo>
  </Wrapper>
);

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${65 / 16}rem;
  padding: 0 clamp(1.2rem, 4vw - 0.25rem, 3rem);
  position: sticky;
  top: 0;
  backdrop-filter: blur(4px) saturate(120%);
  z-index: 100;

  @media (-webkit-min-device-pixel-ratio: 3),
    (-webkit-min-device-pixel-ratio: 2) {
    & {
      -webkit-backdrop-filter: blur(6px) saturate(120%);
    }
  }
`;

const Logo = styled.a`
  cursor: pointer;
  width: 150px;
  padding: 20px;
  padding-left: 0;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  padding: 8px;
`;
