import { useRef, useEffect } from "react";
import styled from "styled-components";
import videojs from "video.js";
import hljs from "highlight.js";

import shell from "highlight.js/lib/languages/shell";
import "videojs-quality-selector-hls";

import "highlight.js/styles/github-dark-dimmed.css";
import "video.js/dist/video-js.css";
import "./App.css";

hljs.registerLanguage("shell", shell);

const videoJsOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  preload: "auto",
  loop: true,
  sources: [
    {
      src: "https://bucket.futjesus.dev/public/big_buck_bunny/playlist.m3u8",
      type: "application/x-mpegURL",
    },
  ],
  onReady: () => {},
  controlBar: {
    playToggle: true,
    skipBackward: true,
    skipForward: true,
    volumePanel: true,
    currentTimeDisplay: true,
    timeDivider: true,
    durationDisplay: true,
    progressControl: true,
    liveDisplay: true,
    seekToLive: true,
    remainingTimeDisplay: true,
    customControlSpacer: true,
    playbackRateMenuButton: true,
    chaptersButton: true,
    descriptionsButton: true,
    subsCapsButton: true,
    audioTrackButton: true,
    pictureInPictureToggle: false,
    fullscreenToggle: true,
  },
};

function App() {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, videoJsOptions, () => {});

      playerRef.current.qualitySelectorHls({
        displayCurrentQuality: true,
        vjsIconClass: "vjs-icon-hd",
      });

      playerRef.current?.on("error", (event) => {
        videojs.log(event);
      });
    } else {
      const player = playerRef.current;
      player.autoplay(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef]);

  useEffect(() => {
    hljs.highlightAll();
  }, []);

  return (
    <Wrapper>
      <Title>
        A demo application showcasing how VOD video encoding works and its
        functionality on the web
      </Title>

      <WrapperVideo ref={videoRef} />

      <WrapperContent>
        <p>
          The web is a quite entertaining world, but so are streaming services
          and how they operate, which led me to understand the functioning and
          the possibility of making a video adaptable on the web. While
          researching, I realized that there has been a library accompanying us
          in the Linux world for a long time, which serves for video
          transcoding, called &nbsp;
          <a href="https://ffmpeg.org/" target="_blank" rel="noreferrer">
            ffmpeg
          </a>
          .
        </p>

        <p>
          The challenge now is to see how, by selecting a video, transcoding can
          be done. Here, I show the configuration I used to transcode the video
          displayed above.
        </p>

        <WrapperCode>
          <pre style={{ overflowX: "auto" }}>
            <Code className="shell" style={{ lineHeight: "1.3" }}>
              {`# 1080p
ffmpeg -i ./big_buck_bunny_1080p_stereo.avi -vf \\
    "scale=w=1920:h=1080:force_original_aspect_ratio=decrease" \\
    -c:v libx264 -preset veryfast -tune zerolatency -b:v 2500k \\
    -maxrate 2500k -bufsize 5000k -c:a aac -b:a 128k -ar 48000 -crf 23 \\
    -profile:v main -g 48 -keyint_min 48 -hls_time 4 \\
    -hls_playlist_type vod -hls_segment_filename \\
    output/1080p/1080p_%03d.ts output/1080p/1080p.m3u8
`}
            </Code>
          </pre>
        </WrapperCode>

        <p>
          The necessary configuration to create an original video and convert it
          to the desired resolution is already in place. Of course, this needs
          to be done for each resolution we wish to support. It's important to
          note that the more resolutions we add, the more space we'll need to
          store all the video chunks, which is precisely what makes it dynamic.
        </p>

        <p>
          From this configuration, I consider the most important aspect to be
          the encoding codec, in this case, libx264, as it is supported by the
          vast majority of browsers. There are other codecs available, but they
          don't have 100% compatibility with all browsers. Another crucial
          aspect is audio encoding, which, like video, we set to acc, also
          supported by the majority of browsers.
        </p>

        <p>
          Once all the video chunks with the desired resolutions are obtained,
          for this case, I encoded them in the following:
        </p>

        <WrapperImage>
          <img
            src="/folders.png"
            style={{ width: "min(250px, 100%)" }}
            alt="folder"
          />
        </WrapperImage>

        <p>
          By the way, the video I'm using is publicly available and can be found
          here:{" "}
          <a
            href="https://download.blender.org/peach/bigbuckbunny_movies"
            target="_blank"
            rel="noreferrer"
          >
            https://download.blender.org/peach/bigbuckbunny_movies
          </a>
        </p>

        <p>
          Next, the following step is to save all the chunks in a location
          accessible from the web. In my case, I store them in my own bucket
          that is exposed to the internet.
        </p>

        <p>
          Additionally, a manifest needs to be added where all the generated
          chunks are defined. In this case, I opted for HLS (HTTP Live
          Streaming) as described here:{" "}
          <a
            href="https://www.dacast.com/blog/hls-streaming-protocol"
            target="_blank"
            rel="noreferrer"
          >
            https://www.dacast.com/blog/hls-streaming-protocol
          </a>
          .
        </p>

        <p>
          Finally, to display them on the web, a player needs to be used. For
          this example, I selected{" "}
          <a href="https://videojs.com/" target="_blank" rel="noreferrer">
            video.js
          </a>
          , which is free and offers great configuration options.
        </p>

        <p>For this example, we have the manifest structured as follows:</p>

        <WrapperCode>
          <pre style={{ overflowX: "auto" }}>
            <Code style={{ lineHeight: "1.3" }}>
              {`
#EXTM3U
#EXT-X-STREAM-INF:BANDWIDTH=2500000,RESOLUTION=1920x1080
1080p/1080p.m3u8
#EXT-X-STREAM-INF:BANDWIDTH=1500000,RESOLUTION=1280x720
720p/720p.m3u8
#EXT-X-STREAM-INF:BANDWIDTH=1000000,RESOLUTION=960x540
540p/540p.m3u8
#EXT-X-STREAM-INF:BANDWIDTH=600000,RESOLUTION=640x360
360p/360p.m3u8
#EXT-X-STREAM-INF:BANDWIDTH=500000,RESOLUTION=480x270
270p/270p.m3u8
#EXT-X-STREAM-INF:BANDWIDTH=400000,RESOLUTION=426x240
240p/240p.m3u8
#EXT-X-STREAM-INF:BANDWIDTH=200000,RESOLUTION=256x144
144p/144p.m3u8
#EXT-X-STREAM-INF:BANDWIDTH=100000,RESOLUTION=120x68
68p/68p.m3u8

`}
            </Code>
          </pre>
        </WrapperCode>

        <p>
          The crucial aspect here is to define the BANDWIDTH required so that
          the player can decide which option is best depending on the internet
          connection.
        </p>

        <p>Here, we can see an example of progressive loading of the video.</p>

        <WrapperImage>
          <img
            src="/download.png"
            style={{ width: "min(500px, 100%)" }}
            alt="desing"
          />
        </WrapperImage>
      </WrapperContent>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  gap: 2rem;
  max-width: 850px;
  margin: 0 auto;

  & a {
    font-weight: bold;
    color: hsla(360, 100%, 100%, 0.9);
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-clip: text;
      color: transparent;
      background-image: linear-gradient(
        to right,
        hsl(271 91% 65%) 0,
        hsl(27 96% 61%) 100%
      );
    }
  }
`;

const Title = styled.h1`
  text-align: center;
  line-height: 1.2;
  font-size: clamp(1.4rem, 6vw - 1.5rem, 2.5rem);
`;

const WrapperContent = styled.section`
  font-size: large;
  color: hsl(100 100% 100% / 75%);
  text-align: justify;
  margin-bottom: 100px;
  width: 100%;

  & p {
    margin: 1rem 0;
  }
`;

const WrapperVideo = styled.div`
  aspect-ratio: 16/9;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
`;

const WrapperCode = styled.div`
  max-width: min(650px, 100%);
  margin: 2rem;
`;

const Code = styled.code`
  border-radius: 16px;
`;

const WrapperImage = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;

  & img {
    border-radius: 8px;
    width: min(650px, 100%);
  }
`;

export default App;
